import { GetServerDataReturn, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import AboutCompanyPage from '../components/AboutCompanyPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IAboutCompany } from '../types/strapi/aboutCompany';
import { IGlobalServerRuntimeData } from '../types/strapi/global';

type TAboutCompany = Pick<IAboutCompany, 'seo' | 'headerBackground' | 'headerText' | 'list' | 'slider'>;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    aboutCompany: TAboutCompany
}

type TProp = TPageProp<IServerRuntimeData>;

const AboutCompany: React.FC<TProp> = ({ serverData }) => {
    useEffect(() => {
        navigate('/');
    }, []);

    return null;
};

export default AboutCompany;

const query = `
  query AboutCompany ($locale: String){
    ${GLOBAL_QUERY}
    aboutCompany(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      headerBackground {
        url
      }
      headerText
      list {
        list_items {
          id
          description
          header
          image {
            url
          }
          target
        }
      }
      slider {
        header
        header_position
        slider_items {
          url
        }
      }
    }
  }
`;

export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const aboutCompanyResponse = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await aboutCompanyResponse.json();

        return {
            props: {
                aboutCompany: data.data.aboutCompany,
                navPanel    : data.data.navPanel,
                global      : data.data.global,
                respondForm : data.data.respondForm,
                footer      : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
